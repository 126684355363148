import { actionTypes } from "./actions"

const INIT_STATE = {
  loading: false,
  nextOfKinList: [],
  success: null,
}

const NextOfKin = (state = INIT_STATE, action) => {
  switch (action.type) {
    // next of kin
    case actionTypes.GET_NEXT_OF_KIN:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_NEXT_OF_KIN_SUCCESS:
      return {
        ...state,
        loading: false,
        nextOfKinList: action.payload,
      }
    // next of kin add
    case actionTypes.ADD_NEXT_OF_KIN:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.ADD_NEXT_OF_KIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    // reset kin states
    case actionTypes.RESET_NEXT_OF_KIN:
      return {
        ...state,
        loading: false,
        success: false
      }
    default:
      return state
  }
}

export default NextOfKin
