import PropTypes from "prop-types"
import React, { useState, useEffect  } from "react"
import { Form, Input, Upload, Space, Typography } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import Breadcrumb from "../../../components/Common/Breadcrumb"
// Custom Scrollbar
import SimpleBar from "simplebar-react"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import AddUpdateClient from "../../../components/clientsForm/clientsForm"
import { getClientDetails } from "store/clients/action"
//i18n
import { withTranslation } from "react-i18next"
const { Text, Title } = Typography
const CavNewClient = props => {
  document.title = "Branding | TRUST"
  const location = useLocation()
  const dispatch = useDispatch()
  const { applicationTwoClientsDetails } = useSelector(
    state => state.AppClients
  )
  const { state } = location

  useEffect(() => {
    if (state?.record) {
      const payload = {
        application_type: 2,
        client_id: +state?.record.id,
      }
      dispatch(getClientDetails(payload))
    }
  }, [state?.record])
  return (
    <React.Fragment>
      <AddUpdateClient
        typeId={2}
        backTo="/cave-clients-list"
        clientsDetails={applicationTwoClientsDetails}
      />
    </React.Fragment>
  )
}

CavNewClient.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CavNewClient)
