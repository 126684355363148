import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"

//Calendar
import calendar from "./calendar/reducer"

// api integration
import Dashboard from "./dashboard/reducer"
import AppClients from "./clients/reducer"
import RadiatorReducer from "./radiators/reducer"
import ResourcesReducer from "./resources/reducer"
import NextOfKinReducer from "./kins/reducer"
import ProfileReducer from "./auth/profile/reducer"
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  calendar,
  //
  Dashboard,
  AppClients,
  RadiatorReducer,
  ResourcesReducer,
  NextOfKinReducer,
  ProfileReducer,
})

export default rootReducer
