import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  RESET_STATES,
} from "./actionTypes"

import { AES, enc } from "crypto-js"
let currentUserFromLocalStorage = null
const encryptedUser = localStorage.getItem("authUser")

if (encryptedUser) {
  try {
    const decryptedUserBytes = AES.decrypt(
      encryptedUser,
      process.env.REACT_APP_USER_DATA_ENC_KEY
    )
    const decryptedUserString = decryptedUserBytes.toString(enc.Utf8)
    const storedUserData = JSON.parse(decryptedUserString)

    const storedTimestamp = storedUserData.timestamp
    const currentTime = new Date().getTime()
    const timeDifference = currentTime - storedTimestamp
    const oneDayInMilliseconds = 1 * 24 * 60 * 60 * 1000

    if (timeDifference > oneDayInMilliseconds) {
      localStorage.removeItem("authUser")
    } else {
      const storedUser = JSON.parse(storedUserData.data)
      currentUserFromLocalStorage = storedUser
    }
  } catch (error) {
    console.error("Error decrypting user data:", error)
  }
}

const initialState = {
  error: "",
  loading: false,
  success: false,
  currentUser: currentUserFromLocalStorage,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        currentUser: action.payload.user,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case RESET_STATES:
      state = { ...state, error: "", success: false, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
