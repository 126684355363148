export const actionTypes = {
  // next of kin
  GET_NEXT_OF_KIN: "GET_NEXT_OF_KIN",
  GET_NEXT_OF_KIN_SUCCESS: "GET_NEXT_OF_KIN_SUCCESS",
  // next of kin add
  ADD_NEXT_OF_KIN: "ADD_NEXT_OF_KIN",
  ADD_NEXT_OF_KIN_SUCCESS: "ADD_NEXT_OF_KIN_SUCCESS",
  //   reset next of kin
  RESET_NEXT_OF_KIN: "RESET_NEXT_OF_KIN",
}

export const getNextOfKin = () => ({
  type: actionTypes.GET_NEXT_OF_KIN,
})

export const getNextOfKinSuccess = payload => ({
  type: actionTypes.GET_NEXT_OF_KIN_SUCCESS,
  payload,
})

// next of kin add
export const addNextOfKin = payload => ({
  type: actionTypes.ADD_NEXT_OF_KIN,
  payload,
})

export const addNextOfKinSuccess = payload => ({
  type: actionTypes.ADD_NEXT_OF_KIN_SUCCESS,
  payload,
})
// reset next of kin
export const resetNextOfKin = () => ({
  type: actionTypes.RESET_NEXT_OF_KIN,
})
