export const actionTypes = {
  GET_APPLICATION_CLIENTS: "GET_APPLICATION_CLIENTS",
  GET_APPLICATION_CLIENTS_SUCCESS: "GET_APPLICATION_CLIENTS_SUCCESS",
  CREATE_CLIENT: "CREATE_CLIENT",
  CREATE_CLIENT_SUCCESS: "CREATE_CLIENT_SUCCESS",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
  GET_CLIENT_INFO: "GET_CLIENT_INFO",
  GET_CLIENT_INFO_SUCCESS: "GET_CLIENT_INFO_SUCCESS",
  // client status change
  CLIENT_STATUS_CHANGE:'CLIENT_STATUS_CHANGE',
  CLIENT_STATUS_CHANGE_SUCCESS:'CLIENT_STATUS_CHANGE_SUCCESS',

  RESET_STATES: "RESET_STATES",
}
// get all clients
export const getApplicationClients = application_type => ({
  type: actionTypes.GET_APPLICATION_CLIENTS,
  application_type,
})

export const getApplicationClientsSuccess = payload => ({
  type: actionTypes.GET_APPLICATION_CLIENTS_SUCCESS,
  payload,
})
// create clients
export const createClients = payload => ({
  type: actionTypes.CREATE_CLIENT,
  payload,
})

export const createClientsSuccess = payload => ({
  type: actionTypes.CREATE_CLIENT_SUCCESS,
  payload,
})
// update clients
export const updateClients = payload => ({
  type: actionTypes.UPDATE_CLIENT,
  payload,
})

export const updateClientsSuccess = payload => ({
  type: actionTypes.UPDATE_CLIENT_SUCCESS,
  payload,
})
// get single client detail
export const getClientDetails = payload => ({
  type: actionTypes.GET_CLIENT_INFO,
  payload,
})

export const getClientDetailsSuccess = payload => ({
  type: actionTypes.GET_CLIENT_INFO_SUCCESS,
  payload,
})
// client Status change
export const clientStatusChange = payload => ({
  type: actionTypes.CLIENT_STATUS_CHANGE,
  payload,
})

export const clientStatusChangeSuccess = payload => ({
  type: actionTypes.CLIENT_STATUS_CHANGE_SUCCESS,
  payload,
})
// reset states
export const resetAppClientsStates = () => ({
  type: actionTypes.RESET_STATES,
})
