import { actionTypes } from "./actions"

const INIT_STATE = {
  loading: false,
  dashboardData: [],
  sideBarCollapse: false,
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: action.payload,
      }

    case actionTypes.TOGGLE_SIDEBAR:
      return {
        ...state,
        sideBarCollapse: !state.sideBarCollapse,
      }

    default:
      return state
  }
}

export default Dashboard
