import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import { Link } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
// Custom Scrollbar
import { Typography, Statistic } from "antd"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { getDashboardData } from "store/dashboard/actions"
import { getTerms, getPrivacy, getAboutUs } from "store/resources/action"
//i18n
import { withTranslation } from "react-i18next"

const SingleDashboard = props => {
  const dispatch = useDispatch()
  const [menu, setMenu] = useState(false)
  const { currentMenu } = useSelector(state => state.Layout)
  const { dashboardData } = useSelector(state => state.Dashboard, shallowEqual)
  const toggle = () => {
    setMenu(!menu)
  }

  useEffect(() => {
    dispatch(getDashboardData())
    dispatch(getAboutUs())
    dispatch(getPrivacy())
    dispatch(getTerms())
  }, [])

  document.title = "Dashboard | TRUST - "
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" />
          <Row>
            <Col xl={4} md={6} className="my-3">
              <Card className="h-100">
                <CardBody className="text-center h-100 d-flex flex-column justify-content-between">
                  <h5 className="fw-medium font-size-24">Total Radiators</h5>
                  <h4 className="fw-medium font-size-24 text-primary">
                    <Statistic value={dashboardData[0]?.radiators} />
                  </h4>
                  {/* <Button className="bg-primary text-white">
                    View Details
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6} className="my-3">
              <Card className="h-100">
                <CardBody className="text-center h-100 d-flex flex-column justify-content-between">
                  <h5 className="fw-medium font-size-24">
                    Number of Radiators In Use
                  </h5>
                  <h4 className="fw-medium font-size-24 text-primary">
                    <Statistic value={dashboardData[0]?.active_radiators} />
                  </h4>
                  {/* <Button className="bg-primary text-white">
                    View Details
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6} className="my-3">
              <Card className="h-100">
                <CardBody className="text-center h-100 d-flex flex-column justify-content-between">
                  <h5 className="fw-medium font-size-24">Number of Clients</h5>
                  <h4 className="fw-medium font-size-24 text-primary">
                    <Statistic value={dashboardData[0]?.users} />
                  </h4>
                  {/* <Button className="bg-primary text-white">
                    View Details
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={6} className="my-3">
              <Card className="h-100">
                <CardBody className="text-center h-100 d-flex flex-column justify-content-between">
                  <h5 className="fw-medium font-size-24">Next of Kin</h5>
                  <h4 className="fw-medium font-size-24 text-primary">
                    <Statistic value={dashboardData[0]?.kins} />
                  </h4>
                  {/* <Button className="bg-primary text-white">
                    View Details
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SingleDashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SingleDashboard)
