import { takeEvery, put, call } from "redux-saga/effects"
import { $host, $authHost } from "store/axios"
import {
  actionTypes,
  getAboutUsSuccess,
  getPrivacySuccess,
  getTermsSuccess,
  getTerms,
  getPrivacy,
  getAboutUs,
  resetResourceState
} from "./action"
import { notification } from "antd"

function* getAboutData() {
  try {
    const response = yield $authHost.get("resources/about_us")
    yield put(getAboutUsSuccess(response.data.data.about_us))
  } catch (error) {
    yield console.error(error)
  }
}

function* getPrivacyData() {
  try {
    const response = yield $authHost.get("resources/privacy_policy")
    yield put(getPrivacySuccess(response.data.data.privacy_policy))
  } catch (error) {
    yield console.error(error)
  }
}

function* getTermsData() {
  try {
    const response = yield $authHost.get("resources/terms_n_conditions")
    yield put(getTermsSuccess(response.data.data.terms_n_conditions))
  } catch (error) {
    yield console.error(error)
  }
}
// update
function* updateAbout(action) {
  const { payload } = action
  try {
    const response = yield $authHost.post("resources/about_us", payload)
    if (response.data.code === 200) {
      notification.success({
        message: response.data.message,
        description: response.data.description,
      })
      yield put(getAboutUs())
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetResourceState())
    }
  }
}

function* updatePrivacy(action) {
  const { payload } = action
  try {
    const response = yield $authHost.post("resources/privacy_policy", payload)
    if (response.data.code === 200) {
      notification.success({
        message: response.data.message,
        description: response.data.description,
      })
      yield put(getPrivacy())
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetResourceState())
    }
  }
}

function* updateTerms(action) {
  const { payload } = action
  try {
    const response = yield $authHost.post(
      "resources/terms_n_conditions",
      payload
    )
    if (response.data.code === 200) {
      notification.success({
        message: response.data.message,
        description: response.data.description,
      })
      yield put(getTerms())
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetResourceState())
    }
  }
}

function* resourcesRootSaga() {
  yield takeEvery(actionTypes.GET_ABOUT, getAboutData)
  yield takeEvery(actionTypes.GET_PRIVACY, getPrivacyData)
  yield takeEvery(actionTypes.GET_TERMS, getTermsData)
  //
  yield takeEvery(actionTypes.UPDATE_ABOUT, updateAbout)
  yield takeEvery(actionTypes.UPDATE_PRIVACY, updatePrivacy)
  yield takeEvery(actionTypes.UPDATE_TERM, updateTerms)
}

export default resourcesRootSaga
