import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Form, Table, Select, DatePicker, TimePicker } from "antd"
import { Container, Row, Button, Col } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import dayjs from "dayjs"
import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"
const { Option } = Select
const CavSoSReports = props => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
    },
  ]
  const data = []
  // for (let i = 0; i < 46; i++) {
  //   data.push({
  //     key: i,
  //     name: "Mike Allen",
  //     date: `11/2/2023`,
  //     time: "7:00 AM",
  //   })
  // }

  document.title = "Usage Report Details | TRUST"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Cave" dashboardRoute='/cave-dashboard' breadcrumbItem="Usage Report Details" />
          <Row>
            <Table
               scroll={{
                x: 1300,
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    // navigate('/cave-usage-report-details')
                    // console.log("event-->", rowIndex)
                  },
                }
              }}
              columns={columns}
              dataSource={data}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CavSoSReports.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CavSoSReports)
