import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { AES, enc } from "crypto-js"
import { useHistory } from "react-router-dom"
import { $host, $authHost } from "store/axios"
import { notification } from "antd"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import {
  apiError,
  loginSuccess,
  resetLoginStates,
  logoutUserSuccess,
} from "./actions"

const loggedInAdmin = {
  id: "1",
  organization_id: null,
  first_name: "Super",
  last_name: "Admin",
  username: "super.admin",
  email: "admin@knarkzdev.com",
  mobile: null,
  role: "super_admin",
  level: 1001,
  status: 1,
  profile_image:
    "https://trust-electric-api.knarkzdev.com/public/profile_images/default_profile_image.png",
  dob: null,
  is_verified: 1,
  is_notification_enable: 1,
  address_line1: null,
  address_line2: null,
  area: null,
  city: null,
  state: null,
  post_code: null,
  country: null,
  created_at: "2024-02-22T12:44:17.260Z",
  created_by: "0",
  last_login_at: "2024-03-29T07:51:28.617Z",
  auth_token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJjb2RlIjoiIiwicm9sZSI6InN1cGVyX2FkbWluIiwibGV2ZWwiOjEwMDEsImZpcnN0X25hbWUiOiJTdXBlciIsImxhc3RfbmFtZSI6IkFkbWluIiwidXNlcm5hbWUiOiJzdXBlci5hZG1pbiIsIm5hbWUiOiJTdXBlciBBZG1pbiIsImVtYWlsIjoiYWRtaW5Aa25hcmt6ZGV2LmNvbSIsIm1vYmlsZSI6IiIsIm9yZ2FuaXphdGlvbl9pZCI6MCwiYXBwbGljYXRpb25fdHlwZSI6MCwiaWF0IjoxNzExNjg5OTE1fQ.yTjkvaR73R4XXYWh8Kq_elMjKp7S8kdqLDscCuLchEA",
  me_as_a_kin: true,
}

function* loginUser({ payload: { user } }) {
  try {
    // const response = yield $host.post(`auth/login`, {
    //   email: user.email,
    //   password: user.password,
    // })
    // const responseData = response.data.data.user
    const responseData = loggedInAdmin

    if (responseData.level === 1001) {
      yield put(loginSuccess(responseData))
      const currentUser = responseData

      localStorage.setItem("isLoggedIn", true)

      const userString = JSON.stringify(currentUser)

      const timestamp = new Date().getTime()

      const userData = {
        data: userString,
        timestamp,
      }

      const encryptedUser = AES.encrypt(
        JSON.stringify(userData),
        process.env.REACT_APP_USER_DATA_ENC_KEY
      ).toString()

      localStorage.setItem("authUser", encryptedUser)
      localStorage.setItem("token", `${currentUser?.auth_token}`)
      sessionStorage.setItem("token", `${currentUser?.auth_token}`)
    } else {
      notification.error({
        message: "Not Allowed To Access",
        description: "This is Super Admin Portal",
      })
      yield put(resetLoginStates())
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetLoginStates())
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    history("/")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
