import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Switch, Table } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  getRadiators,
  toggelRadiators,
  deleteRadiator,
} from "store/radiators/action"
import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"

const RadiatorList = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { radiatorsList, loading } = useSelector(state => state.RadiatorReducer)

  useEffect(() => {
    dispatch(getRadiators())
  }, [])

  const toggleRadiator = (id, isActive) => {
    dispatch(toggelRadiators({ id, isActive: isActive ? 0 : 1 }))
  }

  const columns = [
    {
      title: "uid",
      dataIndex: "uid",
    },
    {
      title: "Place Name",
      dataIndex: "name",
    },
    {
      title: "Minimum Temperature",
      dataIndex: "min_temperature",
    },
    {
      title: "Maximum Temperature",
      dataIndex: "max_temperature",
    },
    {
      title: "Current Temperature",
      dataIndex: "current_temperature",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (data, record) => (
        <Switch
          style={{
            backgroundColor: data === 1 ? "green" : "red",
          }}
          checked={data === 1}
          onChange={() => toggleRadiator(record.id, data === 1)}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: data => (
        <Switch
          style={{
            backgroundColor: data === 1 ? "green" : "red",
          }}
          checked={data === 1 ? true : false}
          // onChange={() => {}}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (data, record) => (
        <>
          {" "}
          <Button
            onClick={() =>
              navigate("/radiator-settings", { state: { record } })
            }
            className="bg-primary text-white"
          >
            Edit
          </Button>
          <Button
            onClick={() => dispatch(deleteRadiator(record.id))}
            className="bg-primary text-white"
            disabled={loading}
          >
            Delete
          </Button>
        </>
      ),
    },
  ]

  document.title = "Radiators | TRUST"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="My Radiator Buddy" dashboardRoute='/buddy-dashboard' breadcrumbItem="Radiators" />
          <Row>
            <Col>
              <Button
                className="bg-primary text-white"
                style={{
                  float: "right",
                }}
                onClick={() => navigate("/radiator-settings")}
              >
                + Add New Radiator
              </Button>
            </Col>
          </Row>
          <Row>
            <Table
              scroll={{
                x: 1300,
              }}
              columns={columns}
              dataSource={radiatorsList}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

RadiatorList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(RadiatorList)
