import { takeEvery, put, call } from "redux-saga/effects"
import { $host, $authHost } from "store/axios"
import {
  actionTypes,
  getRadiators,
  getRadiatorsSuccess,
  createRadiatorsSuccess,
  updateRadiatorsSuccess,
  resetRadiatorStates,
} from "./action"
import { notification } from "antd"

// get radiators
function* getRadiatorsList() {
  try {
    const response = yield $authHost.get(`radiators`)
    yield put(getRadiatorsSuccess(response.data.data.radiators))
  } catch (error) {
    yield console.error(error)
  }
}

// create radiators
function* createRadiator(action) {
  const { payload } = action
  const createRadiator = {
    uid: payload.uid,
    name: payload.name,
    min_temperature: payload.minimumtemperature,
    max_temperature: payload.maximumtemperature,
  }
  try {
    const response = yield $authHost.post(`radiators`, createRadiator)
    notification.success({
      message: response.data.message,
      description: response.data.description,
    })
    yield put(createRadiatorsSuccess(true))
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetRadiatorStates())
    }
  }
}

// update radiators
function* updateRadiator(action) {
  const { payload } = action
  const updateRadiator = {
    name: payload.name,
    min_temperature: payload.minimumtemperature,
    max_temperature: payload.maximumtemperature,
  }
  try {
    const response = yield $authHost.post(
      `radiators/${payload.id}`,
      updateRadiator
    )
    notification.success({
      message: response.data.message,
      description: response.data.description,
    })
    yield put(updateRadiatorsSuccess(true))
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetRadiatorStates())
    }
  }
}

// delete radiators
function* deleteRadiator(action) {
  const { payload } = action
  try {
    const response = yield $authHost.delete(
      `radiators/${payload}`,
      updateRadiator
    )
    notification.success({
      message: response.data.message,
      description: response.data.description,
    })
    yield put(getRadiators())
    yield put(resetRadiatorStates())
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetRadiatorStates())
    }
  }
}

// toggle radiators
function* toggelRadiator(action) {
  const { payload } = action
  const toggelRadiator = {
    is_active: payload.isActive,
  }
  try {
    const response = yield $authHost.patch(
      `radiators/${payload.id}/toggle_active`,
      toggelRadiator
    )
    yield put(getRadiators())
    notification.success({
      message: response.data.message,
      description: response.data.description,
    })
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetRadiatorStates())
    }
  }
}

function* radiatorsSaga() {
  yield takeEvery(actionTypes.GET_RADIATORS, getRadiatorsList)
  yield takeEvery(actionTypes.CREATE_RADIATORS, createRadiator)
  yield takeEvery(actionTypes.UPDATE_RADIATORS, updateRadiator)
  yield takeEvery(actionTypes.DELETE_RADIATORS, deleteRadiator)
  yield takeEvery(actionTypes.TOGGLE_RADIATOR, toggelRadiator)
}

export default radiatorsSaga
