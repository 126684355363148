import { actionTypes } from "./action"

const INIT_STATE = {
  loading: false,
  applicationOneClients: [],
  applicationTwoClients: [],
  applicationOneClientsDetails: {},
  applicationTwoClientsDetails: {},
  success: null,
}

const AppClients = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get all clients
    case actionTypes.GET_APPLICATION_CLIENTS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_APPLICATION_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        applicationOneClients:
          action.payload.applicationType === 1
            ? action.payload.data
            : state.applicationOneClients,
        applicationTwoClients:
          action.payload.applicationType === 2
            ? action.payload.data
            : state.applicationTwoClients,
      }
    // create clients
    case actionTypes.CREATE_CLIENT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    // update clients
    case actionTypes.UPDATE_CLIENT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    // get single client info
    case actionTypes.GET_CLIENT_INFO:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_CLIENT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        applicationOneClientsDetails:
          action.payload.applicationType === 1
            ? action.payload.data
            : state.applicationOneClientsDetails,
        applicationTwoClientsDetails:
          action.payload.applicationType === 2
            ? action.payload.data
            : state.applicationTwoClientsDetails,
      }
    // Chnage Client Status
    case actionTypes.CLIENT_STATUS_CHANGE:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CLIENT_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    // reset states
    case actionTypes.RESET_STATES:
      return {
        ...state,
        loading: false,
        success: null,
        applicationOneClientsDetails: {},
        applicationTwoClientsDetails: {},
      }

    default:
      return state
  }
}

export default AppClients
