import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"

import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
// api
import dashboardSaga from "./dashboard/saga"
import appClientsSaga from "./clients/saga"
import radiatorsSaga from "./radiators/saga"
import resourcesRootSaga from "./resources/saga"
import nextOfKinSaga from "./kins/saga"
import ProfileSaga from "./auth/profile/saga"
export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ForgetSaga(),
    LayoutSaga(),
    // app
    dashboardSaga(),
    appClientsSaga(),
    radiatorsSaga(),
    resourcesRootSaga(),
    nextOfKinSaga(),
    ProfileSaga(),
    fork(calendarSaga),
  ])
}
