import { takeEvery, put, call } from "redux-saga/effects"
import { $host, $authHost } from "store/axios"
import {
  actionTypes,
  getApplicationClientsSuccess,
  getClientDetailsSuccess,
  createClientsSuccess,
  updateClientsSuccess,
  resetAppClientsStates,
} from "./action"
import { notification } from "antd"

// get All clients
function* getApplicationClients(action) {
  const { application_type } = action
  try {
    const response = yield $authHost.get(
      `clients?application_type=${application_type}`
    )
    yield put(
      getApplicationClientsSuccess({
        applicationType: application_type,
        data: response.data.data.clients,
      })
    )
  } catch (error) {
    yield console.error(error)
  }
}

// create client
function* createClients(action) {
  const { payload } = action
  const clientPayload = {
    type: payload.typeId,
    client_name: payload.client_name,
    first_name: payload.firstname,
    last_name: payload.lastname,
    username: payload.username,
    email: payload.email,
    password: payload.confirmPassword,
    mobile: payload.contactnumber,
    address_line1: payload.AddressLine1,
    address_line2: payload.AddressLine2,
    city: payload.city,
    state: payload.state,
    post_code: payload.postcode,
    country: payload.country,
    geofencing_radius: payload.geofencing_radius,
    primary_color: payload.primarycolor,
    primary_dark_color: payload.primarydark,
    secondary_color: payload.secondarycolor,
    accent_color: payload.accentcolor,
    background_color: payload.backgroundcolor,
    title_text_color: payload.titleTextColor,
    header_text_color: payload.headerTextColor,
    button_text_color: payload.buttonTextColor,
    hub_ip: payload.hupIpAddress,
    hub_port: payload.hubport,
    hub_username: payload.hubusername,
    hub_password: payload.hubconfirmPassword,
  }
  try {
    const response = yield $authHost.post(`clients`, clientPayload)
    if (response.data.code === 200) {
      notification.success({
        message: response.data.message,
        description: response.data.description,
      })
      yield put(createClientsSuccess(true))
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetAppClientsStates())
    }
  }
}

// update client
function* updateClients(action) {
  const { payload } = action
  const clientPayload = {
    type: payload.typeId,
    client_name: payload.client_name,
    address_line1: payload.AddressLine1,
    address_line2: payload.AddressLine2,
    mobile: payload.contactnumber,
    email: payload.email,
    city: payload.city,
    state: payload.state,
    post_code: payload.postcode,
    country: payload.country,
    geofencing_radius: payload.geofencing_radius,
    primary_color: payload.primarycolor,
    primary_dark_color: payload.primarydark,
    secondary_color: payload.secondarycolor,
    accent_color: payload.accentcolor,
    background_color: payload.backgroundcolor,
    title_text_color: payload.titleTextColor,
    header_text_color: payload.headerTextColor,
    button_text_color: payload.buttonTextColor,
    hub_ip:
      payload.typeId === 1
        ? null
        : payload.typeId === 2 && payload.hupIpAddress,
    hub_port:
      payload.typeId === 1 ? null : payload.typeId === 2 && payload.hubport,
    hub_username:
      payload.typeId === 1 ? null : payload.typeId === 2 && payload.hubusername,
    hub_password:
      payload.typeId === 1
        ? null
        : payload.typeId === 2 && payload.hubconfirmPassword,
  }
  console.log("updateClientPayload", clientPayload)
  try {
    const response = yield $authHost.post(
      `clients/${payload.client_id}`,
      clientPayload
    )
    if (response.data.code === 200) {
      notification.success({
        message: response.data.message,
        description: response.data.description,
      })
      yield put(updateClientsSuccess(true))
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetAppClientsStates())
    }
  }
}

// client Status change
function* changeClientStatus(action) {
  const { payload } = action
  try {
    const response = yield $authHost.put(
      `client?application_type=${payload.application_type}/${payload.org_id}/status/${payload.value}`
    )
    notification.success({
      message: response.data.message,
      description: response.data.description,
    })
    yield put(getApplicationClients(payload.application_type))
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetAppClientsStates())
    }
  }
}

// get single client info
function* getApplicationClientsDetails(action) {
  const { client_id, application_type } = action.payload

  try {
    const response = yield $authHost.get(
      `/clients/${client_id}?application_type=${application_type}`
    )

    yield put(
      getClientDetailsSuccess({
        applicationType: application_type,
        data: response.data.data.client,
      })
    )
  } catch (error) {
    yield console.error(error)
  }
}

function* AppClientsSaga() {
  yield takeEvery(actionTypes.GET_APPLICATION_CLIENTS, getApplicationClients)
  yield takeEvery(actionTypes.CREATE_CLIENT, createClients)
  yield takeEvery(actionTypes.UPDATE_CLIENT, updateClients)
  yield takeEvery(actionTypes.GET_CLIENT_INFO, getApplicationClientsDetails)
  yield takeEvery(actionTypes.CLIENT_STATUS_CHANGE, changeClientStatus)
}

export default AppClientsSaga
