export const actionTypes = {
  // get user profile
  GET_USER_PROFILE: "GET_USER_PROFILE",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",

  //   rest user Profile states
  RESET_USER_PROFILE: "RESET_USER_PROFILE",
}

export const getUserProfile = () => ({
  type: actionTypes.GET_USER_PROFILE,
})

export const getUserProfileSuccess = payload => ({
  type: actionTypes.GET_USER_PROFILE_SUCCESS,
  payload,
})

// reset next of kin
export const resetUserProfile = () => ({
  type: actionTypes.RESET_USER_PROFILE,
})
