import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Switch, Table, Avatar } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { getApplicationClients, clientStatusChange } from "store/clients/action"
import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch, shallowEqual } from "react-redux"

const ClientsList = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentMenu } = useSelector(state => state.Layout)
  const { applicationOneClients } = useSelector(
    state => state.AppClients,
    shallowEqual
  )

  useEffect(() => {
    const application_type = 1
    dispatch(getApplicationClients(application_type))
  }, [])

  function statusChange(data) {
    if (data === 1) {
      return 0
    } else if (data === 0) {
      return 1
    } else {
      return null
    }
  }

  const columns = [
    // {
    //   title: "Id",
    //   dataIndex: "id",
    // },
    {
      title: "Client Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "Access Code",
      dataIndex: "access_code",
      align: "center",
    },
    {
      title: "Contact",
      dataIndex: "mobile",
      align: "center",
    },
    {
      title: "Geofencing Radius",
      dataIndex: "geofencing_radius",
      align: "center",
    },
    {
      title: "Branding",
      dataIndex: "Branding",
      align: "center",
      render: (data, record) => (
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <div
            style={{
              backgroundColor: record.primary_color,
              width: "20px",
              height: "20px",
              marginRight: "5px",
            }}
          />
          {record.primary_dark_color && (
            <div
              style={{
                backgroundColor: record.primary_dark_color,
                width: "20px",
                height: "20px",
                marginRight: "5px",
              }}
            />
          )}
          {record.secondary_color && (
            <div
              style={{
                backgroundColor: record.secondary_color,
                width: "20px",
                height: "20px",
                marginRight: "5px",
              }}
            />
          )}
          {record.accent_color && (
            <div
              style={{
                backgroundColor: record.accent_color,
                width: "20px",
                height: "20px",
                marginRight: "5px",
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (data, record) => (
        <Switch
          style={{
            backgroundColor: data === 1 ? "green" : "red",
          }}
          onChange={() => {
            const payload = {
              application_type: 1,
              org_id: record?.id,
              value: statusChange(data),
            }
            dispatch(clientStatusChange(payload))
          }}
          checked={data === 1 ? true : false}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (data, record) => (
        <Button
          className="bg-primary text-white"
          onClick={() =>
            navigate("/update-client", {
              state: { record, backToList: "/clients-list" },
            })
          }
        >
          Edit
        </Button>
      ),
    },
  ]

  document.title = "Clients List | TRUST"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="My Radiator Buddy"
            dashboardRoute="/buddy-dashboard"
            breadcrumbItem="Clients List"
          />
          <Row>
            <Col>
              <Button
                className="bg-primary text-white"
                style={{
                  float: "right",
                }}
                onClick={() =>
                  navigate("/new-client", {
                    state: { backToList: "/clients-list" },
                  })
                }
              >
                + Add New Client
              </Button>
            </Col>
          </Row>
          <Row>
            <Table
              scroll={{
                x: 1300,
              }}
              columns={columns}
              dataSource={applicationOneClients}
              rowClassName="center-row"
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ClientsList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ClientsList)
