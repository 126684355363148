import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Skeleton } from "antd"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Form,
  FormFeedback,
  Label,
  Input,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"

import { getUserProfile } from "store/actions"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/user-4.jpg"
// actions

const UserProfile = props => {
  const dispatch = useDispatch()
  const { userProfile } = useSelector(state => state.ProfileReducer)

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your User Name"),
    }),
    onSubmit: values => {
      // dispatch(editProfile(values))
    },
  })

  // useEffect(() => {
  //   dispatch(getUserProfile())
  // }, [])

  document.title = "Profile | TRUST - "
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="TRUST" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  {Object.keys(userProfile).length > 0 ? (
                    <div className="d-flex">
                      <div className="mx-3">
                        <img
                          src={userProfile.profile_image}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <h5>{userProfile.username}</h5>
                          <p className="mb-1">{userProfile.email}</p>
                          <p className="mb-0">Role:{userProfile.role}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Skeleton
                      avatar
                      paragraph={{
                        rows: 2,
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <h4 className="card-title mb-4">Change User Name</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Edit User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  const { success } = state.ProfileReducer
  return { success }
}

export default withRouter(connect(mapStatetoProps, {})(UserProfile))
