import { actionTypes } from "./action"

const INIT_STATE = {
  loading: false,
  aboutUsData: {},
  privacyData: {},
  termsData: {},
}

const Resources = (state = INIT_STATE, action) => {
  switch (action.type) {
    // about
    case actionTypes.GET_ABOUT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_ABOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutUsData: action.payload,
      }
    // privacy
    case actionTypes.GET_PRIVACY:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_PRIVACY_SUCCESS:
      return {
        ...state,
        loading: false,
        privacyData: action.payload,
      }
    // terms
    case actionTypes.GET_TERMS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        termsData: action.payload,
      }
    case actionTypes.UPDATE_TERM:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.RESET_RESOURCE_STATE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default Resources
