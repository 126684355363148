import { takeEvery, put, call } from "redux-saga/effects"
import { $host, $authHost } from "store/axios"
import { actionTypes, getUserProfileSuccess } from "./actions"
import { notification } from "antd"

function* getUserProfileData() {
  try {
    const response = yield $authHost.get("users/profile")
    yield put(getUserProfileSuccess(response.data.data.profile))
  } catch (error) {
    yield console.error(error)
  }
}

function* userProfileRootSaga() {
  yield takeEvery(actionTypes.GET_USER_PROFILE, getUserProfileData)
}

export default userProfileRootSaga
