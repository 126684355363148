import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Switch, Table, Avatar } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { getNextOfKin } from "store/kins/actions"
//i18n
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"

const CavNextKinList = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentMenu } = useSelector(state => state.Layout)
  const [kinsList, setKinsList] = useState([])
  const { nextOfKinList } = useSelector(state => state.NextOfKinReducer)

  useEffect(() => {
    dispatch(getNextOfKin())
  }, [])

  useEffect(() => {
    if (nextOfKinList) {
      const modifiedArray = nextOfKinList.map(kins => ({
        ...kins,
        name: `${kins.first_name} ${kins.last_name}`,
      }))
      setKinsList(modifiedArray)
    }
  }, [nextOfKinList])

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    // {
    //   title: "Image",
    //   dataIndex: "profile_image",
    //   render: data => <Avatar src={data} />,
    // },
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "Contact",
      dataIndex: "mobile",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: data => (
        <Switch
          style={{
            backgroundColor: data === 1 ? "green" : "red",
          }}
          checked={data === 1 ? true : false}
        />
      ),
    },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: (data, record) => (
    //     <Button
    //       className="bg-primary text-white"
    //       // onClick={() => navigate("/cave-new-client", { state: { record } })}
    //     >
    //       Edit
    //     </Button>
    //   ),
    // },
  ]

  document.title = "Clients List | TRUST"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Cave" dashboardRoute='/cave-dashboard' breadcrumbItem="Next Of Kin List" />
          {/* <Row>
            <Col>
              <Button
                className="bg-primary text-white"
                style={{
                  float: "right",
                }}
                onClick={() => navigate("/cave-create-edit-next-kin")}
              >
                + Add Next of Kin
              </Button>
            </Col>
          </Row> */}
          <Row>
            <Table
              scroll={{
                x: 1300,
              }}
              columns={columns}
              dataSource={kinsList}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CavNextKinList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CavNextKinList)
