export const actionTypes = {
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
}

export const getDashboardData = () => ({
  type: actionTypes.GET_DASHBOARD_DATA,
})

export const getDashboardDataSuccess = payload => ({
  type: actionTypes.GET_DASHBOARD_DATA_SUCCESS,
  payload,
})

export const toggleSidebar = () => ({
  type: actionTypes.TOGGLE_SIDEBAR,
})
