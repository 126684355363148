import { actionTypes } from "./action"

const INIT_STATE = {
  loading: false,
  radiatorsList: [],
  success: null,
}

const RadiatorReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get radiator
    case actionTypes.GET_RADIATORS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_RADIATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        radiatorsList: action.payload,
      }
    // create radiator
    case actionTypes.CREATE_RADIATORS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CREATE_RADIATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    // update radiator
    case actionTypes.UPDATE_RADIATORS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_RADIATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      }
    // delete radiator
    case actionTypes.DELETE_RADIATORS:
      return {
        ...state,
        loading: true,
      }
    // toggle radiator
    case actionTypes.TOGGLE_RADIATOR:
      return {
        ...state,
        loading: false,
      }
    // reset radiator
    case actionTypes.RESET_RADIATOR_STATES:
      return {
        ...state,
        loading: false,
        success: null,
      }
    default:
      return state
  }
}

export default RadiatorReducer
