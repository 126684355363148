import radiatorIcon from "assets/images/radiatorIcon.png"
import uniLogoIcon from "assets/images/university-logo.png"
import aboutIcon from "assets/images/about.png"
import contactusIcon from "assets/images/contactus.png"
import termsIcon from "assets/images/sideBar/terms.png"
import privacyIcon from "assets/images/sideBar/privacy-policy.png"
export const sidebarData = [
  {
    id: 1,
    title: "My Radiator Buddy",
    image: radiatorIcon,
    href: "/buddy-dashboard",
    icon: false,
    childerns: [
      {
        id: 1.1,
        title: "Clients",
        href: "/clients-list",
      },
      // {
      //   id: 1.2,
      //   title: "Radiators",
      //   href: "/radiators",
      // },
      {
        id: 1.3,
        title: "Usage Report",
        href: "/usage-report",
      },
      // {
      //   id: 1.4,
      //   title: "Terms & Condition",
      //   href: "/terms-conditions",
      // },
      // {
      //   id: 1.5,
      //   title: "Privacy Policy",
      //   href: "/privacy-policy",
      // },
      // {
      //   id: 1.6,
      //   title: "About Us",
      //   href: "/about-us",
      // },
    ],
  },
  {
    id: 2,
    title: "Cave",
    image: uniLogoIcon,
    href: "/cave-dashboard",
    icon: false,
    childerns: [
      {
        id: 2.1,
        title: "Clients",
        href: "/cave-clients-list",
      },
      {
        id: 2.2,
        title: "Radiators",
        href: "/cave-radiators",
      },
      {
        id: 2.3,
        title: "Next of Kin",
        href: "/cave-kin-list",
      },
      {
        id: 2.4,
        title: "Usage Report",
        href: "/cave-usage-report",
      },
      {
        id: 2.5,
        title: "SOS Report",
        href: "/cave-sos-reports",
      },
      // {
      //   id: 2.6,
      //   title: "Terms & Condition",
      //   href: "/cave-terms-condition",
      // },
      // {
      //   id: 2.7,
      //   title: "Privacy Policy",
      //   href: "/cave-privacy-policy",
      // },
      // {
      //   id: 2.8,
      //   title: "About Us",
      //   href: "/cave-about-us",
      // },
    ],
  },
  {
    id: 3,
    title: "Terms & Condition",
    image: termsIcon,
    href: "/terms-conditions",
  },
  {
    id: 4,
    title: "Privacy Policy",
    image: privacyIcon,
    href: "/privacy-policy",
  },
  {
    id: 5,
    title: "About Us",
    image: aboutIcon,
    href: "/about-us",
  },
  // {
  //   id: 6,
  //   title: "Contact Us",
  //   image: contactusIcon,
  //   href: "/contact-us",
  //   icon: false,
  // },
]
