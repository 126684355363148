import { takeEvery, put, call } from "redux-saga/effects"
import { $host, $authHost } from "store/axios"
import { actionTypes, getDashboardDataSuccess } from "./actions"
import { notification } from "antd"

function* getDashboardData() {
  try {
    const response = yield $authHost.get("admin/dashboard")
    yield put(getDashboardDataSuccess(response.data.data.counts))
  } catch (error) {
    yield console.error(error)
  }
}

function* dashboardSaga() {
  yield takeEvery(actionTypes.GET_DASHBOARD_DATA, getDashboardData)
}

export default dashboardSaga
