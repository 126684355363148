import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Form, Input, Upload, Space, ColorPicker } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { useSelector, useDispatch } from "react-redux"
import { addNextOfKin, resetNextOfKin } from "store/kins/actions"
const CreateEditNextKin = props => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { success, loading } = useSelector(state => state.NextOfKinReducer)
  document.title = "Add Clients | TRUST"
  useEffect(() => {
    if (success) {
      form.resetFields()
      dispatch(resetNextOfKin())
    }
  }, [success])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Cave" breadcrumbItem="Next of kin" />
          <Row>
            <Col>
              <Form
                form={form}
                layout="vertical"
                size="large"
                // initialValues={{
                //   requiredMarkValue: requiredMark,
                // }}
                // onValuesChange={onRequiredTypeChange}
                // requiredMark={
                //   requiredMark === "customize"
                //     ? customizeRequiredMark
                //     : requiredMark
                // }
                onFinish={values => {
                  dispatch(addNextOfKin(values))
                }}
                autoComplete="off"
              >
                <Form.Item>
                  <Form.Item
                    name="email"
                    label="Kin Email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Kin Email!",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                    ]}
                    // style={{
                    //   display: "inline-block",
                    //   width: "calc(50% - 8px)",
                    // }}
                  >
                    <Input placeholder="kin@kin.com" />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Space
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      className="bg-primary text-white px-4"
                      type="primary"
                      onClick={() => {
                        navigate("/cave-kin-list")
                      }}
                    >
                      Back to list
                    </Button>
                    <Button
                      className="bg-primary text-white px-4"
                      type="primary"
                      disabled={loading}
                      htmltype="submit"
                    >
                      Save
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CreateEditNextKin.propTypes = {
  t: PropTypes.any,
}

export default CreateEditNextKin
