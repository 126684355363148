import PropTypes, { object } from "prop-types"
import React, { useEffect, useState } from "react"
import { Form, Input, Upload, Space, ColorPicker } from "antd"
import { Container, Row, Col, Button } from "reactstrap"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumb from "components/Common/Breadcrumb"
// Custom Scrollbar
import {
  createRadiators,
  updateRadiators,
  resetRadiatorStates,
} from "store/radiators/action"
import { useLocation } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const EditRadiator = props => {
  document.title = "Ratiators Settings | TRUST"
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location
  const { loading, success } = useSelector(state => state.RadiatorReducer)
  const isUpdateForm = state?.record && Object.keys(state?.record).length > 0

  useEffect(() => {
    if (success) {
      form.resetFields()
      dispatch(resetRadiatorStates())
    }
  }, [success])

  useEffect(() => {
    if (isUpdateForm) {
      form.setFieldValue("uid", state.record.uid)
      form.setFieldValue("name", state.record.name)
      form.setFieldValue("minimumtemperature", state.record.min_temperature)
      form.setFieldValue("maximumtemperature", state.record.max_temperature)
    }
  }, [state?.record])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="My Radiator Buddy"
            breadcrumbItem="Ratiators Settings"
          />
          <Row>
            <Col>
              <Form
                form={form}
                layout="vertical"
                size="large"
                // initialValues={{
                //   requiredMarkValue: requiredMark,
                // }}
                // onValuesChange={onRequiredTypeChange}
                // requiredMark={
                //   requiredMark === "customize"
                //     ? customizeRequiredMark
                //     : requiredMark
                // }
                onFinish={values => {
                  if (isUpdateForm) {
                    values.id = state.record.id
                    dispatch(updateRadiators(values))
                  } else {
                    dispatch(createRadiators(values))
                  }
                }}
              >
                <Form.Item>
                  <Form.Item
                    name="uid"
                    label="Uid"
                    rules={[
                      {
                        required: true,
                        message: "Please input Uid",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Input
                      disabled={isUpdateForm && true}
                      placeholder="ASDGHJASDJHGSD"
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label="Place Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Place Name",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Input placeholder="Living Room" />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Form.Item
                    name="minimumtemperature"
                    label="Minimum Temperature"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Minimum Temperature!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Input type="number" min={0} placeholder="20 C" />
                  </Form.Item>
                  <Form.Item
                    name="maximumtemperature"
                    label="Maximum Temperature"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Maximum Temperature!",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Input type="number" min={0} placeholder="10 C" />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Space
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      className="bg-primary text-white px-4"
                      type="primary"
                      onClick={() => {
                        navigate("/radiators")
                      }}
                    >
                      Back to list
                    </Button>
                    <Button
                      className="bg-primary text-white px-4"
                      type="primary"
                      htmltype="submit"
                      disabled={loading}
                    >
                      {isUpdateForm ? "Update" : "Save"}
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EditRadiator.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(EditRadiator)
