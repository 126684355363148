import { actionTypes } from "./actions"

const initialState = {
  loading: false,
  userProfile: {},
  success: null,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_PROFILE:
      state = { ...state, loading: true }
      break
    case actionTypes.GET_USER_PROFILE_SUCCESS:
      state = { ...state, loading: false, userProfile: action.payload }
      break
    case actionTypes.RESET_USER_PROFILE:
      state = { ...state, loading: false, success: null }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
