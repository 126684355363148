import { takeEvery, put, call } from "redux-saga/effects"
import { $host, $authHost } from "store/axios"
import {
  actionTypes,
  getNextOfKinSuccess,
  addNextOfKinSuccess,
  resetNextOfKin,
} from "./actions"
import { notification } from "antd"

function* getNextOfKinData() {
  try {
    const response = yield $authHost.get("kins")
    yield put(getNextOfKinSuccess(response.data.data.kins))
  } catch (error) {
    yield console.error(error)
  }
}

// add next of kin
function* addNextOfKin(action) {
  const { payload } = action
  try {
    const response = yield $authHost.post(`kins`, payload)
    notification.success({
      message: response.data.message,
      description: response.data.description,
    })
    yield put(addNextOfKinSuccess(true))
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetNextOfKin())
    }
  }
}

function* nextOfKinSaga() {
  yield takeEvery(actionTypes.GET_NEXT_OF_KIN, getNextOfKinData)
  yield takeEvery(actionTypes.ADD_NEXT_OF_KIN, addNextOfKin)
}

export default nextOfKinSaga
