export const actionTypes = {
  GET_RADIATORS: "GET_RADIATORS",
  GET_RADIATORS_SUCCESS: "GET_RADIATORS_SUCCESS",
  // create radiator
  CREATE_RADIATORS: "CREATE_RADIATORS",
  CREATE_RADIATORS_SUCCESS: "CREATE_RADIATORS_SUCCESS",
  // update radiator
  UPDATE_RADIATORS: "UPDATE_RADIATORS",
  UPDATE_RADIATORS_SUCCESS: "UPDATE_RADIATORS_SUCCESS",
  // delete radiator
  DELETE_RADIATORS: "DELETE_RADIATORS",
  // toggle radiator
  TOGGLE_RADIATOR: "TOGGLE_RADIATOR",
  //   reset radiator
  RESET_RADIATOR_STATES: "RESET_RADIATOR_STATES",
}

export const getRadiators = () => ({
  type: actionTypes.GET_RADIATORS,
})

export const getRadiatorsSuccess = payload => ({
  type: actionTypes.GET_RADIATORS_SUCCESS,
  payload,
})
// create radiator
export const createRadiators = payload => ({
  type: actionTypes.CREATE_RADIATORS,
  payload,
})

export const createRadiatorsSuccess = payload => ({
  type: actionTypes.CREATE_RADIATORS_SUCCESS,
  payload,
})

// update radiator
export const updateRadiators = payload => ({
  type: actionTypes.UPDATE_RADIATORS,
  payload,
})

export const updateRadiatorsSuccess = payload => ({
  type: actionTypes.UPDATE_RADIATORS_SUCCESS,
  payload,
})

// delete radiator
export const deleteRadiator = payload => ({
  type: actionTypes.DELETE_RADIATORS,
  payload,
})

// toggel radiator
export const toggelRadiators = payload => ({
  type: actionTypes.TOGGLE_RADIATOR,
  payload,
})

// reset radiator
export const resetRadiatorStates = () => ({
  type: actionTypes.RESET_RADIATOR_STATES,
})
